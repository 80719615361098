import Form from '@rjsf/antd';
import { getDefaultRegistry } from '@rjsf/core';
import { DescriptionFieldProps, FieldProps, RJSFValidationError, TitleFieldProps } from "@rjsf/utils";
import { customizeValidator } from '@rjsf/validator-ajv8';
import Ajv2020 from "ajv/dist/2020";
import { Typography } from 'antd';
import 'antd/es/alert/style/index.css';
import 'antd/es/input-number/style/index.css';
import React, { createRef, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import CustomArrayFieldTemplate from "../SubmissionBuilder/SubmissionEditor/FormGenerator/CustomArrayFieldTemplate";

const { Title } = Typography
const { ArrayField } = getDefaultRegistry().fields;
const validator = customizeValidator({ AjvClass: Ajv2020 });


const CustomArrayField = function (props: FieldProps) {
    // TODO: This is temporary to support references. Will be changed in the next release
    const newSchema = { ...props.schema };
    if (!newSchema.items) {
        newSchema.items = { "type": "string" }
    }
    return (
        <ArrayField {...props} schema={newSchema} />
    )
};

function transformErrors(errors: RJSFValidationError[]) {
    return errors.map((error: RJSFValidationError) => {
        if (error.name === "pattern") {
            error.message = "Input doesn't match required pattern"
        }
        return error;
    });
}


function TitleFieldTemplate(props: TitleFieldProps) {
    const { title } = props;
    return (
        <Title style={{ textAlign: 'center' }} level={4}>
            {title}
        </Title>
    );
}


const DescriptionFieldTemplate = (props: DescriptionFieldProps) => {
    const { description, id } = props;
    if (id == 'undefined__description') {
        return null
    }
    return (
        <header id={id}>
            {description}
        </header>
    )
}

const SchemaBasedForm = ({ style, recordId, schema, formData = null, onSave, onError, onSubmit }: { style?: React.CSSProperties, recordId: any, schema: any, formData?: any, onSave?: Function, onError?: Function, onSubmit?: Function }) => {
    const formRef = createRef<any>();
    const uiSchema = {
        "ui:rootFieldId": recordId,
        "ui:classNames": "schema-form",
        "ui:submitButtonOptions": {
            "submitText": "",
        },
        title: {
            "classNames": "task-title"
        }
    };


      
    const handleChange = ({formData}:{formData:  any}) => {

        formRef.current?.submit()
        onSave && onSave(formData)

    }

    const debouncedHandleChange = debounce(handleChange, 1500);

    const handleError = (errors: any) => {
        console.log(errors)
        onError && onError(errors)
       
        // onSave && onSave(formRef.current?.props?.formData)

    }

    const handleSubmit = ({formData}:{formData:  any}) => {
        console.log('submitted?')
        onSubmit && onSubmit(formData)
    }

    const json = schema
    // causes validation caching issues when different schemas have same $id
    delete json['$id']

    const memoizedForm = useMemo(() => {
        return (
            <div style={style}>
                <Form
                    id={recordId}
                    liveValidate
                    showErrorList={true}
                    ref={formRef}
                    fields={{ArrayField: CustomArrayField}}
                    {...(formData && { formData })}
                    transformErrors={transformErrors}
                    templates={{
                        ArrayFieldTemplate: CustomArrayFieldTemplate,
                        TitleFieldTemplate: TitleFieldTemplate,
                        DescriptionFieldTemplate: DescriptionFieldTemplate
                    }}
                    key={recordId}
                    onError={handleError}
                    uiSchema={uiSchema} schema={json} validator={validator}
                    onChange={debouncedHandleChange}
                    onSubmit={handleSubmit}
                />
            </div>
        )
    }, [formData])

    return memoizedForm


}

export default SchemaBasedForm;