import React, { useEffect, useState } from 'react';
import { Tree, Button, Collapse, Menu, Dropdown, Modal, Typography, Row, Col } from 'antd';
import { ExclamationCircleOutlined, FileAddOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import AddServiceItemModal from './ServiceItemModal';
import AppMetadataModal from './AppMetadataModal';
import ApplicationYAMLModal from './ApplicationYAMLModal';

export declare type Key = string | number;
const { confirm } = Modal;

interface ApplicationPanelProps {
    application: string;
    appDeclarationData: any
    selectedServiceItem: ServiceItem | null;
    selectedService: string | null;
    setSelectedService: Function;
    serviceItems?: ServiceItemsData;
    onAddServiceItem: (serviceName: string, serviceItemName: string) => void;
    onEditAppMetadata: (appName: string, metadata: object) => void;
    onNodeSelect: (nodes: Key[], info: any) => void;
    serviceItemsWithErrors: ServiceItem[];
    onRemoveApp?: (applicationName: string) => void; // For handling app selection
}

interface ServiceItem {
    appName: string;
    name: string;
    serviceType: string;
    data?: any;
}

// Define the service items data type
type ServiceItemsData = {
    [serviceType: string]: ServiceItem[];
};

const { TreeNode } = Tree;
const { Panel } = Collapse;
const { Text } = Typography;

const ApplicationPanel = ({ application, serviceItems, onAddServiceItem, onNodeSelect, serviceItemsWithErrors, onRemoveApp, selectedServiceItem, selectedService, setSelectedService, onEditAppMetadata, appDeclarationData, ...props }: ApplicationPanelProps) => {

    const [isServiceItemModalOpen, setIsServiceItemModalOpen] = useState<boolean>(false)
    const [isAppMetadataModalOpen, setIsAppMetadataModalOpen] = useState<boolean>(false)
    const [isAppYamlModalOpen, setIsAppYamlModalOpen] = useState<boolean>(false)

    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

    useEffect(() => {
        if(selectedService)
            setExpandedKeys([selectedService])
    }, [selectedService]);

    interface Data {
        [key: string]: any;
    }
    interface Item {
        [key: string]: string | number;
    }

    interface ServiceItem {
        appName: string;
        name: string;
        serviceType: string;
        data?: any;
    }

    const handleDelete = () => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to remove ${application}?`,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                console.log('ok')
                onRemoveApp && onRemoveApp(application)
            }
        })
    }

    const appContextMenu = (
        <Menu>
            <Menu.Item onClick={() => setIsAppYamlModalOpen(true)} key="0">
                View App YAML
            </Menu.Item>

            <Menu.Item onClick={() => setIsAppMetadataModalOpen(true)} key="1">
                Edit Metadata
            </Menu.Item>
            <Menu.Item onClick={() => handleDelete()} key="2">
                Remove Application
            </Menu.Item>

        </Menu>
    );

    const stopPropagation = (e: any) => e.stopPropagation();

    const renderAppPanelTitle = (appName: string) => {
        if (serviceItemsWithErrors.some(serviceItem => serviceItem.appName === appName)) {
            return <Text type={"danger"}>{appName} <ExclamationCircleOutlined /></Text>
        }
        return <Text strong>{appName}</Text>
    }

    const onSelect = async (nodes: Key[], info: any) => onNodeSelect(nodes, info)
    const onExpand = (expandedKeysValue: any) => setExpandedKeys(expandedKeysValue)

    const renderServiceItemNodeTitle = (applicationName: string, item: Item) => {
        if (serviceItemsWithErrors.some(serviceItem => serviceItem.name === item.name && serviceItem.appName === applicationName)) {
            return <span style={{ color: 'red' }}>{item.name} <ExclamationCircleOutlined /></span>
        }
        return item.name
    }

    const renderTreeNodes = (applicationName: string, data: Data, depth: number, maxDepth: number) => {
        if (depth >= maxDepth) {
            return null; // Limit reached, stop recursion
        }
        return Object.keys(data).map((key: string) => (
            <TreeNode expanded selectable={false} title={key} key={key}>
                {Array.isArray(data[key]) ? (
                    data[key].map((item: Item) => (
                        <TreeNode expanded title={renderServiceItemNodeTitle(applicationName, item)} key={`${key}\x1F${item.name}`} />
                    ))
                ) : (
                    renderTreeNodes(applicationName, data[key], depth + 1, maxDepth)
                )}
            </TreeNode>
        ));
    };

    return (
        <Panel
            {...props}
            forceRender
            header={renderAppPanelTitle(application)}
            key={application}
            extra={
                <div onClick={stopPropagation}>
                    <Dropdown overlay={appContextMenu} trigger={['click']}>
                        <MoreOutlined />
                    </Dropdown>
                </div>
            }
        >
            <Row>
                <Col span={24}>
                    <Tree expandedKeys={expandedKeys}  selectedKeys={[`${selectedServiceItem?.serviceType}\x1F${selectedServiceItem?.name}`]} onSelect={onSelect} onExpand={onExpand} showLine defaultExpandAll defaultExpandParent>
                        {renderTreeNodes(application, appDeclarationData?.services, 0, 2)}
                    </Tree>
                </Col>
            </Row>
            <Row style={{display: "flex", justifyContent: "center", alignContent: "center", marginTop: "5px"}}>
                <Button type='dashed' icon={<FileAddOutlined /> } onClick={() => setIsServiceItemModalOpen(true)}>New Service Item</Button>
            </Row>
            {isServiceItemModalOpen && <AddServiceItemModal handleModalSubmit={onAddServiceItem} modalVisible={isServiceItemModalOpen} setModalVisible={setIsServiceItemModalOpen} />}
            {isAppMetadataModalOpen && <AppMetadataModal appName={application} metadata={appDeclarationData?.metadata} handleModalSubmit={onEditAppMetadata} modalVisible={isAppMetadataModalOpen} setModalVisible={setIsAppMetadataModalOpen} />}
            {isAppYamlModalOpen && <ApplicationYAMLModal appName={application} appDeclarationData={appDeclarationData} modalVisible={isAppYamlModalOpen} setModalVisible={setIsAppYamlModalOpen} />}

        </Panel>
    )
}

export default ApplicationPanel