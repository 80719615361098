import {ListBase, ListProps, useListContext} from "react-admin";
import {Card, Table, Input, Tooltip, Typography, Button} from 'antd';
import React, {useEffect, useState} from "react";
import SkeletonTable, { SkeletonTableColumnsType } from "../Common/SkeletonTable";
import {Link, useLocation} from "react-router-dom";
import { getTagForChangeStatus } from "../Common/Tags";
import { formatDateAndTime } from "../../helpers/utils";
import SubmissionExpandedRow from "./SubmissionExpandedRow";
import {CaretRightOutlined} from "@ant-design/icons";
const { Text } = Typography;
import LazyExpandedRow from "../Common/LazyExpandedRow";
import {onExpandOnlyOneRow} from "../Common/tableUtils";


const ListView = (props: ListProps) => {
    const { ids, data, loading, filterValues, setFilters, page, total, perPage, setPage, setPerPage } = useListContext();
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

    const location = useLocation();
    useEffect(() => {
        setExpandedRowKeys([Number(location.hash.substring(1))])
    }, [location]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Commit ID',
            dataIndex: 'commit_id',
            key: 'commit_id',
            render: (text: any) => text == '' ? '-' : text
        },
        {
            title: 'Change Status',
            key: 'change_status',
            render: (text: any, record: any) => (
                <Link to={`/change_instances?filter={"commit_id":"${record.commit_id}"}`} >
                    <span className='service-item-card-text link'>{getTagForChangeStatus(record.change_status)}</span>
                </Link>
            )
        },
        {
            title: 'Created',
            key: 'created',
            render: (text: any, record: any) => formatDateAndTime(record.created)
        },
        {
            render: (record: any) => (
                <Link to={"/submissions/"+record.id}>
                  <Button> More Detail</Button>
                </Link>
            )
        },
    ];

    const setFilterCommitId = (element: any) => {
        setFilters({ ...filterValues, 'commit_id': element.target.value }, {})
    }

    const tableData: Array<Object> = [];
    data && ids.forEach(id => tableData.push(data[id]));
    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Submissions"
            extra={<Input placeholder="commit id" onChange={setFilterCommitId} />}
        >
            <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
                <Table
                    pagination={{
                        total: total,
                        pageSize: perPage,
                        pageSizeOptions: [25, 50, 100],
                        onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                        current: page,
                        onChange: page => setPage(page)
                    }}
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    expandable={{
                        expandedRowRender: (record) => <LazyExpandedRow lightRecord={record} resource={"submissions"} ExpandedRowComponent={SubmissionExpandedRow}/>,
                        expandRowByClick: true,
                        expandedRowKeys: expandedRowKeys,
                        onExpand: onExpandOnlyOneRow(setExpandedRowKeys)
                    }}
                />
            </SkeletonTable>
        </Card>
    )
}

const SubmissionsList = (props: ListProps) => (
    <ListBase
        filter={{light: true}}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={25}
        {...props}
    >
        <ListView />
    </ListBase>
)

export default SubmissionsList;
