import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Collapse, Modal, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { HttpError, useDataProvider, useGetIdentity, useNotify, useQuery, useRedirect } from 'react-admin';
import { ExtendedDataProvider } from '../../../types';
import SchemaBasedForm from '../../ServiceCatalogue/SchemaBasedForm';
import { useBuilderWizardContext } from '../BuilderWizardProvider';
import AddApplicationModal from './AddApplicationModal';
import ApplicationPanel from './ApplicationPanel';
import useDeclarationDataEditor from './useDeclarationDataEditor';
import ValidationErrors from '../ValidationErrors';
import { isSubmitButtonVisible } from './utils'
import TeamMetadataModal from './TeamMetadataModal';
import {getActiveTeamLocalStorage} from "../../Common/utils";

const { confirm } = Modal;

export declare type Key = string | number;

interface ServiceItem {
    appName: string;
    name: string;
    serviceType: string;
    data?: any;
}


const SubmissionEditor = () => {
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const [selectedItemSchema, setSelectedItemSchema] = useState<any>(null)
    const [selectedItem, setSelectedItem] = useState<ServiceItem | null>(null)
    const [isAppModalOpen, setIsAppModalOpen] = useState<boolean>(false)
    const [isTeamMetadataModalOpen, setIsTeamMetadataModalOpen] = useState<boolean>(false)
    const { identity } = useGetIdentity()
    const [isSchemaLoading, setIsSchemaLoading] = useState<boolean>(false)
    const [serviceItemsWithErrors, setServiceItemsWithErrors] = useState<ServiceItem[]>([]);
    const notify = useNotify();
    const redirect = useRedirect();

    const teamName = getActiveTeamLocalStorage ()?.name

    const { setSelectedApplication, selectedApplication, setServiceItemsData, setSelectedService, selectedService, setIsCurrentStepValid, setDeclarationData, declarationData, setValidationErrors, validationErrors } = useBuilderWizardContext();
    const { getServiceItemData, partialUpdateDeclaration, deleteAppFromDeclaration, partialDeleteDeclaration, partialUpdateAppMetadata, partialUpdateTeamMetadata } = useDeclarationDataEditor({ declarationData, setDeclarationData, setSelectedItem });

    const { Title } = Typography;
    const { data: submissionListData, loaded, error: submissionListError } = useQuery({
        type: 'getList',
        resource: 'submissions',
        payload: {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "id", order: "DESC" },
            filter: {}
        }
    });

    const validateSubmission = () => {
        dataProvider.createAction('submissions', { data: declarationData, verb: 'validate/' })
            .then((response: any) => {
                if (response?.data?.is_valid) {
                    notify('Validation successful', "success")
                    setValidationErrors(false)
                }
            }).catch((error: HttpError) => {
                console.error(error.body)
                notify('Validation failed', "error")
                setValidationErrors(error.body.errors)
            })

    }
    const submitSubmission = () => {
        dataProvider.createAction('submissions', { data: declarationData, verb: 'submit/' })
            .then((response: any) => {
                notify('Submission successful', "success")
                if (response?.data?.id) {
                    redirect(`/submissions/${response?.data?.id}`)
                }
            }).catch((error: HttpError) => {
                console.error(error.body)
                notify('Submission failed', "error")
            })

    }

    useEffect(() => {
        if (submissionListError)
            notify ('Fetching latest submission failed', "error")

        if (!teamName) {
            return
        }
        if (loaded && !declarationData && submissionListData[0]) {
            // setServiceItemsData({ [`${selectedApplication?.name}`]: appServiceItemData })
            const latestSubmission = submissionListData[0]
            setDeclarationData(latestSubmission.request_json)
            // setIsCurrentStepValid(true)
        } else if (loaded && !declarationData && (!submissionListData || submissionListData.length == 0)) {
            setDeclarationData({ [teamName]: {} })
        }

    }, [submissionListData, loaded, submissionListError, teamName]);

    useEffect(() => {
        setIsCurrentStepValid(serviceItemsWithErrors.length == 0)
    }, [serviceItemsWithErrors])

    const getServiceSchema = async (serviceName: string | Number | null) => {
        return dataProvider.getList('browse_services', {
            pagination: { page: 1, perPage: 20 },
            sort: { field: "name", order: "asc" },
            filter: {
                'name_exact': serviceName
            }
        }
        ).then((data: any) => {
            return data
        })
    }
    const handleItemUpdate = (formData: any) => {
        selectedItem && formData?.name && partialUpdateDeclaration({
            application: selectedApplication,
            itemType: selectedItem?.serviceType!,
            itemName: formData?.name,
            updatedData: formData
        })
    }

    const handleSuccessSubmit = (formData: any) => {
        setServiceItemsWithErrors(prevItemsWithErrors =>
            prevItemsWithErrors.filter(erroredItem => !(erroredItem.appName === selectedItem!.appName && erroredItem.name === selectedItem!.name))
        )
    }

    const handleDeleteApp = (applicationName: string) => {
        deleteAppFromDeclaration({ application: applicationName })
    }

    const handleDeleteItem = () => {
        confirm({
            okText: "Remove",
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to remove this service item from the declaration?',
            onOk() {
                partialDeleteDeclaration({ application: selectedApplication, itemType: selectedItem?.serviceType!, itemName: selectedItem?.name! })
                setSelectedItemSchema(null)
            },
        });
    }
    const handleItemError = (errors: any) => {
        setServiceItemsWithErrors(prevItemsWithErrors => [...prevItemsWithErrors, selectedItem!]);
        errors && setIsCurrentStepValid(false)
    }

    const onAddNewApp = (appName: string) => {
        if (!teamName) {
            return
        }
        const amendedData = JSON.parse(JSON.stringify(declarationData));
        if (amendedData[teamName][appName]) {
            console.warn("app already exists")
            notify('Application with that name already exists', 'error')
            return;
        }
        amendedData[teamName][appName] = { services: {} }
        setDeclarationData(amendedData)
        setSelectedApplication(appName)
    }

    const onEditTeamMetadata = (teamMetadata: object) => {
        partialUpdateTeamMetadata({ updatedData: teamMetadata })
    }

    const onAddServiceItem = async (serviceName: string, serviceItemName: string) => {
        setSelectedItemSchema(null)
        const schema = await getServiceSchema(serviceName)
        partialUpdateDeclaration({
            application: selectedApplication,
            itemType: serviceName,
            itemName: serviceItemName,
            updatedData: { name: serviceItemName }
        })

        setSelectedItemSchema(schema.data[0]?.schema)
        setSelectedService(serviceName)
    }

    const onEditAppMetadata = (appName: string, appMetadata: object) => {
        partialUpdateAppMetadata({ application: appName, updatedData: appMetadata },)
    }


    const handleServiceItemNodeSelect = async (nodes: Key[], info: any) => {
        setIsSchemaLoading(true)
        const itemName = info?.node?.key.split('\x1F')
        if (itemName.length < 2) {
            console.error('Invalid item name')
        }
        const serviceItemName = itemName[1]
        const serviceName = itemName[0]
        const serviceItem: ServiceItem = getServiceItemData(selectedApplication, serviceName, serviceItemName, declarationData)
        try {
            const schema = await getServiceSchema(serviceItem.serviceType);
            setSelectedItemSchema(schema.data[0]?.schema);
            setSelectedItem(serviceItem);
        } finally {
            setIsSchemaLoading(false); // Hide the spinner (even if there's an error)
        }

    }

    const handleAppSelect = (key: string | string[]) => {
        if (Array.isArray(key)) {
            console.warn("Received an array of keys, only using the first element.");
            key = key[0];
        }
        // if no app is expanded clear item view
        setSelectedItem(null)
        setSelectedItemSchema(null)
        setSelectedApplication(key)
    }
    if (identity && !teamName) {
        return <Alert message="Please create or join a team to use the Submission Builder" type="error" />
    }

    if (!(declarationData && teamName)) {
        return <Spin style={{ 'width': '100%' }} />
    }

    const isRemoteValid = (typeof validationErrors === 'boolean' && validationErrors === false);
    return (
        <Card
            className="header-solid"
            bordered={false}
        >
            {validationErrors && <ValidationErrors errors={validationErrors} />}
            <Row>
                <Col style={{ borderRight: '1px solid #dddddd' }} span={8}>
                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                            <Collapse
                                onChange={handleAppSelect}
                                accordion
                                // @ts-ignore
                                activeKey={selectedApplication}
                            >
                                {
                                    Object.keys(declarationData[teamName])
                                        .filter(application => application !== 'metadata')
                                        .map((application: string) => (
                                            <ApplicationPanel
                                                key={application}
                                                serviceItemsWithErrors={serviceItemsWithErrors}
                                                application={application}
                                                selectedServiceItem={selectedItem}
                                                setSelectedService={setSelectedService}
                                                selectedService={selectedService}
                                                appDeclarationData={declarationData[teamName]?.[application]}
                                                onNodeSelect={handleServiceItemNodeSelect}
                                                onAddServiceItem={onAddServiceItem}
                                                onEditAppMetadata={onEditAppMetadata}
                                                onRemoveApp={handleDeleteApp}
                                            />
                                        )
                                        )
                                }
                            </Collapse>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '32px' }}>
                        <Col >
                            <Button icon={<PlusOutlined />} onClick={() => setIsAppModalOpen(true)}>New Application</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '5px' }}>
                        <Col >
                            <Button icon={<EditOutlined />} onClick={() => setIsTeamMetadataModalOpen(true)}>Team Metadata</Button>
                        </Col>
                    </Row>
                    {isTeamMetadataModalOpen && <TeamMetadataModal metadata={declarationData[teamName]?.metadata} handleModalSubmit={onEditTeamMetadata} modalVisible={isTeamMetadataModalOpen} setModalVisible={setIsTeamMetadataModalOpen} />}
                    {isAppModalOpen && <AddApplicationModal handleModalSubmit={onAddNewApp} modalVisible={isAppModalOpen} setModalVisible={setIsAppModalOpen} />}
                </Col>

                {!selectedItemSchema && <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} span={16}>
                    {isSchemaLoading && <Spin style={{ 'width': '100%' }} />}
                    {!selectedItemSchema && <Title style={{ color: 'gray' }} level={4}> Select or Add a Service Item</Title>}
                </Col>}

                <Col span={16}>
                    {selectedItemSchema && !isSchemaLoading && <SchemaBasedForm onError={handleItemError} onSave={handleItemUpdate} onSubmit={handleSuccessSubmit} formData={selectedItem?.data} key={selectedItemSchema?.id} style={{ padding: '5px 15px' }} recordId={selectedItemSchema?.id} schema={selectedItemSchema?.schema} />}
                    {selectedItemSchema && !isSchemaLoading && <Button style={{ position: 'absolute', bottom: 0, right: 0 }} onClick={handleDeleteItem} danger><DeleteOutlined /> Remove Service item</Button>}
                </Col>
            </Row>
            <br></br>
            <Row>
                <Col span={24}>
                    <Space>
                        <Button onClick={() => validateSubmission()}>Validate</Button>
                        {
                            isSubmitButtonVisible() &&
                            <Button type='primary' disabled={!isRemoteValid} onClick={() => submitSubmission()}>Submit</Button>
                        }
                    </Space>
                </Col>
            </Row>
        </Card>
    )
}

export default SubmissionEditor;