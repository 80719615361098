export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const CONSUMER_VIEW_MODE = 'CONSUMER';
export const SET_WELCOME_SCREEN_SHOWN = 'SET_WELCOME_SCREEN_SHOWN';
export const SET_SERVICE_ITEMS_VIEW_MODE = 'SET_SERVICE_ITEMS_VIEW_MODE'
export const SERVICE_ITEMS_GRID_VIEW = 'SERVICE_ITEMS_GRID_VIEW'
export const SERVICE_ITEMS_LIST_VIEW = 'SERVICE_ITEMS_LIST_VIEW'

export const setServiceItemsViewMode = (
    mode: string
) => ({
    type: SET_SERVICE_ITEMS_VIEW_MODE,
    payload: mode,
});

export const setViewMode = (
    mode: string
) => ({
    type: SET_VIEW_MODE,
    payload: mode,
});

export const setWelcomeScreenShown = (
    shown: boolean
) => ({
    type: SET_WELCOME_SCREEN_SHOWN,
    payload: shown,
});