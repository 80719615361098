import { Card, Checkbox, Col, List, Row, Select, Typography, Input } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useEffect, useState } from 'react';
import { useDataProvider, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import {ExtendedDataProvider, SelectItem} from '../../types';
import {
    getFilterTextForChangeStatus,
    getTagForChangeStatus,
    getTagForRuntimeStates
} from '../Common/Tags';
import FaIcon from '../Icons/FaIcon';
import { faThLarge, faUsers } from '@fortawesome/free-solid-svg-icons';

const { Search } = Input;
const { Title } = Typography;
const { Option } = Select;

const GridView = () => {
    const { ids, data, loading, filterValues, setFilters, page, total, perPage, setPage, setPerPage } = useListContext();
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const [applications, setApplications] = useState<SelectItem[] | null>(null);
    const [services, setServices] = useState<SelectItem[] | null>(null);
    const [runtimeStateFilters, setRunTimeStateFilters] = useState<any>([])
    const [changeStateFilters, setChangeStateFilters] = useState<any>([])

    // initial filter param from url
    const [initialApplication, setInitialApplication] = useState<string|null>(filterValues.application_id)

    const onAppSelect = (value: string) => setFilters({ ...filterValues, application_id: [value] }, {});
    const onServiceSelect = (value: string) => setFilters({ ...filterValues, service_id: [value] }, {});

    const handleRunTimeStateFilterChange = (checked: Array<CheckboxValueType>) => {
        setRunTimeStateFilters(checked)
        setFilters({ ...filterValues, 'runtime_state': checked.join() }, {})
    }

    const handleChangeStateFilterChange = (checked: Array<CheckboxValueType>) => {
        setFilters({ ...filterValues, 'change_state': checked.join() }, {})
        setChangeStateFilters(checked)
    }

    const applicationsResource = "applications";
    useEffect(() => {
        if(applicationsResource){
            dataProvider.getFilterOptions(
                applicationsResource,
                {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const appFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            appFilter.push({ 'text': item.name, 'value': item.id })
                            if(item.id.toString()===initialApplication)
                                setInitialApplication(item.name)
                        }
                        setApplications(appFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [applicationsResource])

    const servicesResource = "consumer_services";
    useEffect(() => {
        if(servicesResource){
            dataProvider.getFilterOptions(
                servicesResource,
                {
                    pagination: { page: 1, perPage: 20 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const serviceFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            serviceFilter.push({ 'text': item.name, 'value': item.id })
                        }
                        setServices(serviceFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [servicesResource])

    useEffect(() => {
        if (filterValues.change_state) {
            setChangeStateFilters(filterValues.change_state.split(','))
        }
    }, [filterValues.change_state]);

    useEffect(() => {
        if (filterValues.runtime_state) {
            setRunTimeStateFilters(filterValues.runtime_state.split(','))
        }
    }, [filterValues.runtime_state]);

    const onSearch = (value: string) => setFilters({ ...filterValues, name: value }, {});

    const tableData: Array<Object> = [];
    ids.forEach(id => {
        tableData.push(data[id])
    });

    return (
        <>
            <Row gutter={24}>
                <Col sm={8} xxl={4} xl={6} span={6} xs={0}>
                    <div style={{ height: '100%', borderRightColor: '#f0f0f0', borderRightWidth: '1px', borderRightStyle: 'solid', padding: '20px' }}>
                        <Row gutter={[8, 15]}>
                            <Col span={24}>
                                <h6 className='text-sm text-muted'>Name</h6>
                                <Search
                                    className='item-search'
                                    allowClear
                                    placeholder="Search"
                                    onSearch={onSearch}
                                    enterButton
                                    style={{ width: '100%', marginBottom: '10px' }}
                                />
                                <h6 className='text-sm text-muted'>Application</h6>
                                {
                                    applications &&
                                    <Select
                                        defaultValue={initialApplication}
                                        placeholder="Select Application"
                                        style={{ width: '100%' }}
                                        onChange={onAppSelect}
                                    >
                                        <Option key={0} value="">All Apps</Option>
                                        {
                                            applications &&
                                            applications.map(app => <Option key={app.value} value={app.value}>{app.text}</Option>)
                                        }
                                </Select>
                                }
                            </Col>
                            <Col span={24}>
                                <h6 className='text-sm text-muted'>Service</h6>
                                <Select
                                    value={services && filterValues.service_id}
                                    placeholder="Select Service"
                                    style={{ width: '100%' }}
                                    onChange={onServiceSelect}
                                >
                                    <Option key={0} value="">
                                        All Services
                                    </Option>
                                    {services?.map(service => {
                                        return (
                                            <Option key={service.value} value={service.value}>
                                                {service.text}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </Col>
                            <Col span={24}>
                                <h6 className='text-sm text-muted'>Change State</h6>

                                <Checkbox.Group
                                    style={{}}
                                    value={changeStateFilters}
                                    onChange={handleChangeStateFilterChange}>
                                    {['ALL_CHANGES_COMPLETED', 'CHANGES_APPROVED', 'CHANGES_PENDING', 'CHANGES_REJECTED', 'CHANGES_ERRORED'].map(tag => (
                                        <Row style={{ 'paddingBottom': '10px' }}>
                                            <Checkbox
                                                style={{ float: 'right' }}
                                                value={tag}
                                            >
                                                {getTagForChangeStatus(tag)}
                                            </Checkbox>
                                        </Row>
                                    ))}
                                </Checkbox.Group>
                            </Col>
                            <Col span={24}>
                                <h6 className='text-sm text-muted'>Runtime State</h6>
                                <Checkbox.Group
                                    value={runtimeStateFilters}
                                    onChange={handleRunTimeStateFilterChange}>
                                    {['IN_SERVICE', 'REQUESTED', 'DECOMMISSIONED'].map(tag => (
                                        <Row key={tag} style={{ 'paddingBottom': '10px' }}>
                                            <Checkbox
                                                style={{ float: 'right' }}
                                                value={tag}
                                            >
                                                {getTagForRuntimeStates(tag)}
                                            </Checkbox>
                                        </Row>
                                    ))}
                                </Checkbox.Group>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xxl={20} xl={18} span={18} sm={16} xs={24}>
                    <Row>
                        <Col span={24}>
                            <List
                                pagination={{
                                    pageSizeOptions: [25, 50, 100],
                                    onShowSizeChange: (_current, size) => setPerPage(size), showSizeChanger: true,
                                    total: total, pageSize: perPage, current: page, onChange: page => setPage(page)
                                }}
                                grid={{ gutter: 24, column: 3, xs: 1, sm: 2, xxl: 4 }}
                                dataSource={tableData}
                                loading={loading}
                                style={{ margin: '20px' }}
                                renderItem={(item: any) => (
                                    <List.Item>
                                        <Link to={`/service_items/${item.id}/`}>
                                            <Card hoverable bodyStyle={{ padding: '20px' }}
                                                title={
                                                    <>
                                                        <Title style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} title={item.name} level={4}>{item.name}</Title>
                                                        <span className='text-sm'>{item.service.name}</span>
                                                    </>
                                                }>
                                                <span className='service-item-card-text'>{getTagForRuntimeStates(item.runtime_state)}</span>
                                                <Link to={`/change_instances?filter={"application_id":"${item.application.id}","service_item_id":"${item.id}","state":"${getFilterTextForChangeStatus(item.change_state)}"}`} >
                                                    <span className='service-item-card-text link'>{getTagForChangeStatus(item.change_state)}</span>
                                                </Link>
                                                <Row gutter={6}>
                                                    <Col>
                                                        <Row gutter={6}>
                                                            <Col className='text-sm'>{FaIcon(faThLarge)}</Col>
                                                            <Col> <span className='service-item-card-text text-sm'> {item.application.name}</span></Col>
                                                        </Row>
                                                        <Row gutter={6}>
                                                            <Col className='text-sm'>{FaIcon(faUsers)}</Col>
                                                            <Col> <span className='service-item-card-text text-sm'> {item.service_owner_team.name}</span></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>


                                            </Card>
                                        </Link>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Col>


            </Row>
        </>
    )
}


export default GridView;
