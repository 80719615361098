import { Button, Card, Radio, RadioChangeEvent, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useQuery, useNotify } from 'react-admin';
import ReactJson from 'react-json-view';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { default as PrismYaml } from 'react-syntax-highlighter/dist/esm/languages/prism/yaml';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import yaml from 'js-yaml';
import { CopyOutlined } from '@ant-design/icons';
SyntaxHighlighter.registerLanguage('yaml', PrismYaml);
const isJson = (data: any) => {
    try {
        JSON.parse(data);
    } catch (e) {
        return false;
    }
    return true;
}

const ServiceExample = ({ style, record }: { style?: React.CSSProperties, record: any }) => {
    const [displayFormat, setDisplayFormat] = useState<'json' | 'yaml'>("json");
    const notify = useNotify();
    const obj: any = {};
    const key: string = displayFormat;
    obj[key] = "true";

    const { data, loaded, error, loading } = useQuery({
        type: 'getActionNonJson',
        resource: 'browse_services',
        payload: { id: record.id, verb: 'examples', filter: { ...obj } },
    });

    const handleRadioChange = (e: RadioChangeEvent) => {
        setDisplayFormat(e.target.value)
    }

    if (!loaded) {
        return <Spin style={{ 'width': '100%' }} />
    }

    const onCopy = (data: string) => {
        navigator.clipboard.writeText(data)
        notify('Copied to clipboard')
    }

    return (
        <div style={style}>
            <Radio.Group onChange={handleRadioChange} size="small" buttonStyle="solid" optionType="button"
                defaultValue={'json'}>
                <Radio value="json">JSON</Radio>
                <Radio value="yaml">YAML</Radio>
            </Radio.Group>
            {loading && <Card style={{ 'minHeight': '100px' }} ><Spin style={{ 'width': '100%', 'margin': '35px auto' }} /></Card>}
            <Tooltip title="Copy To Clipboard">
                <Button
                icon={<CopyOutlined/>}
                    onClick={() => onCopy(displayFormat == 'json' ? data : yaml.dump(yaml.load(data)))}
                    style={{float:'right', padding: 0}}
                />
            </Tooltip>
            {isJson(data) && displayFormat == 'json' && <ReactJson enableClipboard={false} style={{ padding: '20px' }} src={JSON.parse(data)} displayArrayKey={false} displayDataTypes={false} name={false} displayObjectSize={false} />}
            {!loading && data && displayFormat == 'yaml' && <SyntaxHighlighter customStyle={{ backgroundColor: 'white' }} style={materialLight} language="yaml">{yaml.dump(yaml.load(data))}</SyntaxHighlighter>}

        </div>
    )
}

export default ServiceExample;