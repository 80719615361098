
interface ServiceItem {
    appName: string;
    name: string;
    serviceType: string;
    data?: any;
}
type UseDeclarationDataEditor = {
    getServiceItemData: (appName: string | null,serviceType: string, itemName: string, declarationData: any) => ServiceItem;
    partialUpdateDeclaration: (params: { application: string | null; itemType: string; itemName: string; updatedData: any }) => void;
    partialDeleteDeclaration: (params: { application: string | null; itemType: string; itemName: string }) => void;
    partialUpdateAppMetadata: (params: { application: string; updatedData: any  }) => void;
    partialUpdateTeamMetadata: (params: { updatedData: any  }) => void;
    deleteAppFromDeclaration: (params: {application: string}) => void;
};

const useDeclarationDataEditor = ({ declarationData, setDeclarationData, setSelectedItem }: { declarationData: any | null, setDeclarationData: Function, setSelectedItem: Function }): UseDeclarationDataEditor => {

    const getServiceItemData = (appName: string | null, serviceType: string, itemName: string, declarationData: any): ServiceItem => {
        if (!appName) {
            throw new Error(`App name/team name cant be null`);
        }
        const teamName = Object.keys(declarationData)[0]

        for (const [type, items] of Object.entries(declarationData[teamName][appName]?.services)) {
            if (Array.isArray(items) && type == serviceType) {
                const foundItem = items.find((item: any) => item.name === itemName);
                if (foundItem) {
                    return {
                        appName: appName,
                        name: foundItem.name,
                        serviceType: type,
                        data: foundItem
                    }
                }
            }
        }
        throw new Error(`Item '${itemName}' not found.`);
    };

    const partialUpdateDeclaration = ({ application, itemType, itemName, updatedData }: { application: string | null, itemType: string, itemName: string, updatedData: any }) => {

        if (!(declarationData)) {
            console.error(declarationData)
            return;
        }

        if (!application) {
            throw new Error(`App name cant be null`);
        }

        const teamName = Object.keys(declarationData)[0]

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName][application].services[itemType]) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName][application].services[itemType] = [];
        }
        const itemIndex = tempData[teamName][application].services[itemType].findIndex((item: any) => item.name === itemName);

        if (itemIndex === -1) {
            tempData[teamName][application].services[itemType].push({ name: itemName, ...updatedData });
        }
        else {
            tempData[teamName][application].services[itemType][itemIndex] = {
                ...tempData[teamName][application].services[itemType][itemIndex],
                ...updatedData
            };
        }
        setDeclarationData(tempData)
        console.log(`${itemType} "${itemName}" updated successfully!`);

        const serviceItem: ServiceItem = getServiceItemData(application, itemType, itemName, tempData)
        setSelectedItem(serviceItem)


    }

    const deleteAppFromDeclaration = ({application}:{application: string}) => {

        if (!(declarationData && application)) {
            console.error("missing data")
        }
        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName][application])
            {
                console.error('Missing data');
                return;
            }

        
        const updatedServiceItemsData = JSON.parse(JSON.stringify(declarationData));
        delete updatedServiceItemsData[teamName][application];
        setDeclarationData(updatedServiceItemsData)

    }

    const partialUpdateAppMetadata = ({ application, updatedData }: { application: string ,  updatedData: any }) => {
        if (!declarationData || !application ) {
            console.error('Missing data or selectedApplication');
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName][application])
            {
                console.error('Missing data');
                return;
            }

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName][application].metadata) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName][application].metadata = {};
        }
        tempData[teamName][application].metadata = updatedData;
        setDeclarationData(tempData)
    }
    
    const partialUpdateTeamMetadata = ({ updatedData }: { updatedData: any }) => {
        if (!declarationData ) {
            console.error('Missing data or selectedApplication');
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        if (!declarationData[teamName])
            {
                console.error('Missing data');
                return;
            }

        const tempData = JSON.parse(JSON.stringify(declarationData));
        if (!tempData[teamName].metadata) {
            // If itemType doesn't exist, create a new entry for it
            tempData[teamName].metadata = {};
        }
        tempData[teamName].metadata = updatedData;
        setDeclarationData(tempData)
    }

    function partialDeleteDeclaration({ application, itemType, itemName }: { application: string | null, itemType: string, itemName: string }) {

        if (!(declarationData)) {
            console.error(declarationData)
            return;
        }

        const teamName = Object.keys(declarationData)[0]
        // Ensure data and application exist
        if (!application || !teamName) {
            console.error('Missing teamName or selectedApplication');
            return;
        }

        const itemIndex = declarationData[teamName][application].services[itemType]?.findIndex((item: any) => item.name === itemName);

        // Ensure itemType and item exist
        if (itemIndex === undefined) {
            console.error(`Item '${itemName}' (type '${itemType}') not found.`);
            return;
        }
        const updatedServiceItemsData = JSON.parse(JSON.stringify(declarationData));

        // Delete the item
        updatedServiceItemsData[teamName][application].services[itemType].splice(itemIndex, 1);
        // Delete the item type if there arent any items remaining
        if (updatedServiceItemsData[teamName][application].services[itemType].length === 0) {
            delete updatedServiceItemsData[teamName][application].services[itemType];
        }

        console.log(`${itemType} "${itemName}" deleted successfully!`);
        setSelectedItem(null)
        setDeclarationData(updatedServiceItemsData)

    }

    return {
        getServiceItemData,
        partialUpdateDeclaration,
        partialDeleteDeclaration,
        partialUpdateTeamMetadata,
        partialUpdateAppMetadata,
        deleteAppFromDeclaration
    }
}

export default useDeclarationDataEditor;