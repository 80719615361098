import React, {useEffect, useState} from "react";
import {ExtendedDataProvider, GeneralFilterProps, SelectItem} from "../../types";
import {Divider, Select, Space, Tag} from "antd";
import {useDataProvider} from "react-admin";

const { CheckableTag } = Tag;
const { Option } = Select;

const GeneralFilter =  ({ filterValues , setFilterValues, applicationsResource, serviceItemsResource, teamsResource, servicesResource, style, spaceDirection="vertical", stateFilterEnabled=true, changeTypeFilterEnabled=true}: GeneralFilterProps) => {
    const dataProvider: ExtendedDataProvider = useDataProvider();

    const [teams, setTeams] = useState<SelectItem[] | null>(null)
    const [applications, setApplications] = useState<SelectItem[] | null>(null)
    const [serviceItems, setServiceItems] = useState<SelectItem[] | null>(null)
    const [services, setServices] = useState<SelectItem[] | null>(null)

    const selectedStatesArray: string[] = filterValues?.state?.split(",")
    const [selectedStates, setSelectedStates] = useState<string[]>(selectedStatesArray?selectedStatesArray:['PENDING', 'APPROVED'])
    const [selectedChangeTypes, setSelectedChangeTypes] = useState<any>(['CREATE', 'MODIFY', 'DELETE'])

    const [initialApplication, setInitialApplication] = useState<string|null>(filterValues.application_id)
    const [initialServiceItem, setInitialServiceItem] = useState<string|null>(filterValues.service_item_id)



    const StatesData: string[] = ['PENDING', 'APPROVED', 'COMPLETED', 'ERROR', 'CLOSED', 'REJECTED'];
    const ChangeTypes = ['CREATE', 'MODIFY', 'DELETE'];

    const onConsumerSelect = (value: string) => {
        setFilterValues({ ...filterValues, 'consumer_team_id': value, 'application_id': '', 'service_item_id': ''}, {})
    }

    const onAppSelect = (value: string) => {
        setFilterValues({ ...filterValues, 'application_id': value, 'service_item_id': '' })
    }

    const onServiceItemSelect = (value: string) => {
        setFilterValues({ ...filterValues, 'service_item_id': value })
    }

    const onServiceSelect = (value: string) => {
        setFilterValues({ ...filterValues, 'service_id': value })
    }

    const handleStateChange = (value: any) => {
        setSelectedStates(value)
        setFilterValues({ ...filterValues, 'state': value.join() })
    }

    const handleChangeTypeChange = (change_type: any, checked: boolean) => {
        const nextSelectedChangeTypes = checked ? [...selectedChangeTypes, change_type] : selectedChangeTypes.filter((t: any) => t !== change_type);
        setSelectedChangeTypes(nextSelectedChangeTypes)
        setFilterValues({ ...filterValues, 'change_type': nextSelectedChangeTypes.join() })
    }

    const filteredStates = StatesData.filter((o) => !selectedStates.includes(o));

    useEffect(() => {
        if(teamsResource){
            dataProvider.getFilterOptions(
                teamsResource,
                {
                    pagination: { page: 1, perPage: 0 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const teamFilter: SelectItem[] | null = []
                    if (data) {
                        for (let item of data) {
                            teamFilter.push({ 'text': item.name, 'value': item.id.toString() })
                        }
                        setTeams(teamFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [teamsResource, selectedStates, selectedChangeTypes])

    useEffect(() => {
        if(applicationsResource){
            dataProvider.getFilterOptions(
                applicationsResource,
                {
                    pagination: { page: 1, perPage: 0 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {
                        'consumer_team_id': filterValues.consumer_team_id,
                    }
                })
                .then(({ data }) => {
                    const appFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            appFilter.push({ 'text': item.name, 'value': item.id })
                            if(item.id.toString()===initialApplication)
                                setInitialApplication(item.name)
                        }
                        setApplications(appFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [filterValues.consumer_team_id, applicationsResource, selectedStates, selectedChangeTypes])

    useEffect(() => {
        if(serviceItemsResource){
            dataProvider.getFilterOptions(
                serviceItemsResource,
                {
                    pagination: { page: 1, perPage: 0 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {
                        'application_id': filterValues.application_id,
                    }
                })
                .then(({ data }) => {
                    const serviceItemFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            serviceItemFilter.push({ 'text': item.name, 'value': item.id })
                            if(item.id.toString()===initialServiceItem)
                                setInitialServiceItem(item.name)
                        }
                        setServiceItems(serviceItemFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [filterValues.application_id, serviceItemsResource, selectedStates, selectedChangeTypes])

    useEffect(() => {
        if(servicesResource){
            dataProvider.getFilterOptions(
                servicesResource,
                {
                    pagination: { page: 1, perPage: 0 },
                    sort: { field: 'name', order: 'asc' },
                    filter: {}
                })
                .then(({ data }) => {
                    const serviceFilter: any[] = []
                    if (data) {
                        for (let item of data) {
                            serviceFilter.push({ 'text': item.name, 'value': item.id })
                        }
                        setServices(serviceFilter);
                    }
                })
                .catch(error => {console.log(error)})
        }
    }, [filterValues, servicesResource, selectedStates, selectedChangeTypes])

    return (
        <Space direction={spaceDirection} style={style}>
            {
                teamsResource &&
                teams &&
                <Select
                    showSearch
                    optionFilterProp="children"
                    // @ts-ignore
                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                    value={filterValues.consumer_team_id}
                    placeholder="Select Consumer"
                    style={{ display: 'block', minWidth: '150px' }}
                    onChange={onConsumerSelect}
                >
                    <Option key={0} value="">All Teams</Option>
                    {
                        teams &&
                        teams.map(team => <Option key={team.value} value={team.value}>{team.text}</Option>)
                    }
                </Select>
            }

            {
                applicationsResource &&
                applications &&
                <Select
                    showSearch
                    optionFilterProp="children"
                    // @ts-ignore
                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                    disabled={!filterValues.consumer_team_id && !!teamsResource}
                    defaultValue={initialApplication}
                    placeholder="Select Application"
                    style={{ display: 'block', minWidth: '150px' }}
                    onChange={onAppSelect}
                >
                    <Option key={0} value="">All Apps</Option>
                    {
                        applications &&
                        applications.map(app => <Option key={app.value} value={app.value}>{app.text}</Option>)
                    }
                </Select>
            }

            {
                serviceItemsResource &&
                serviceItems &&
                <Select
                    showSearch
                    optionFilterProp="children"
                    // @ts-ignore
                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                    disabled={!filterValues.application_id}
                    defaultValue={initialServiceItem}
                    placeholder="Select Service Item"
                    style={{ display: 'block', minWidth: '150px' }}
                    onChange={onServiceItemSelect}
                >
                    <Option value="">All Service Items</Option>
                    {
                        serviceItems &&
                        serviceItems.map(service => <Option key={service.value} value={service.value}>{service.text}</Option>)
                    }
                </Select>
            }

            {
                servicesResource &&
                <Select
                    showSearch
                    optionFilterProp="children"
                    // @ts-ignore
                    filterOption={(input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase())}
                    value={filterValues.service_id}
                    placeholder="Select Service"
                    style={{ display: 'block',  minWidth: '150px' }}
                    onChange={onServiceSelect}
                >
                    <Option key={0} value="">All Services</Option>
                    {
                        services &&
                        services.map(service => <Option key={service.value} value={service.value}>{service.text}</Option>)
                    }
                </Select>
            }
            {
                stateFilterEnabled &&
                <>
                    States:
                    <Select
                            mode="multiple"
                            placeholder="Select states"
                            value={selectedStates}
                            onChange={handleStateChange}
                            style={{ width: '100%'}}
                            options={filteredStates.map((item) => ({value: item, label: item}))}
                    />
                </>
            }
            {
                changeTypeFilterEnabled &&
                <Space>
                    Change Types:
                    <Divider type="vertical" />
                    {ChangeTypes.map(changeType => (
                        <CheckableTag
                            key={changeType}
                            checked={selectedChangeTypes && selectedChangeTypes.indexOf(changeType) > -1}
                            onChange={checked => handleChangeTypeChange(changeType, checked)}
                        >
                            {changeType}
                        </CheckableTag>
                    ))}
                </Space>
            }
        </Space>
    )
}

export default GeneralFilter;
