import { Button, Modal, Tooltip } from 'antd';
import React from 'react';
import { useNotify } from 'react-admin';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useBuilderWizardContext } from '../BuilderWizardProvider';

import { CopyOutlined } from '@ant-design/icons';
import yaml from 'js-yaml';


const ApplicationYAMLModal = ({ modalVisible, setModalVisible, appName, appDeclarationData }: { modalVisible: boolean, setModalVisible: Function, appName: string, appDeclarationData: any }) => {
    const { declarationData, setValidationErrors } = useBuilderWizardContext();
    const notify = useNotify();

    const onCopy = (data: string) => {
        navigator.clipboard.writeText(data)
        notify('Copied to clipboard')
    }

    const handleOk = () => {
        setModalVisible(false)
    }

    const handleCancel = () => {
        setModalVisible(false)
    }

    return (
        <Modal
        title={`${appName}.yml`}
        visible={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
    >
        <div>
            <Tooltip title="Copy To Clipboard">
                <Button
                    icon={<CopyOutlined />}
                    onClick={() => onCopy(yaml.dump({[appName]: appDeclarationData }))}
                    style={{ float: 'right', padding: 0 }}
                />
            </Tooltip>
            <SyntaxHighlighter customStyle={{ backgroundColor: 'white' }} style={materialLight} language="yaml">{yaml.dump({[appName]: appDeclarationData })}</SyntaxHighlighter>
        </div>
        </Modal>
    )
}

export default ApplicationYAMLModal