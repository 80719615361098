import { Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { formatDateAndTime } from '../../helpers/utils';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import { getFilterTextForChangeStatus, getTagForChangeStatus, getTagForRuntimeStates } from '../Common/Tags';
import GeneralFilter from "../ChangeInstances/GeneralFilter";
import { tableOnChangeGenerator } from "../Common/tableUtils";

const { Search } = Input;


const ListView = () => {
    const { ids, data, loading, setSort, filterValues, setFilters, page, total, setPerPage, perPage, setPage } = useListContext();
    const [columns, setColumns] = useState<any[]>([])

    useEffect(() => {
        const columns_ = [
            {
                title: 'Service Item',
                dataIndex: 'name',
                key: 'name',
                render: (text: any, record: any) =>
                (
                    <div title={record.name} style={{ display: 'grid', placeItems: 'stretch' }}>
                        <Link style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} to={`/service_items/${record.id}`}>{record.name}</Link>
                    </div>
                ),
                sorter: { key: "name" },
            },
            {
                title: 'State',
                key: 'runtime_state',
                render: (text: any, record: any) => getTagForRuntimeStates(record.runtime_state),
                sorter: { key: "runtime_state" },
            },
            {
                title: 'Change Status',
                key: 'change_state',
                render: (text: any, record: any) => (
                    <Link to={`/change_instances?filter={"application_id":"${record.application.id}","service_item_id":"${record.id}","state":"${getFilterTextForChangeStatus(record.change_state)}"}`} >
                        <span className='service-item-card-text link'>{getTagForChangeStatus(record.change_state)}</span>
                    </Link>
                )
            },
            {
                title: 'Application',
                dataIndex: ['application', 'name'],
                key: 'application',
            },
            {
                title: 'Service',
                dataIndex: ['service', 'name'],
                key: 'service',
            },
            {
                title: 'Service Owner',
                dataIndex: ['service_owner_team', 'name'],
                key: 'service_owner_team',
            },
            {
                title: 'Last Modified',
                key: 'modified',
                render: (text: any, record: any) => formatDateAndTime(record.modified),
                sorter: { key: "modified" },
            },
        ];
        setColumns(columns_);
    }, [])

    const onSearch = (value: string) => {
        setFilters({ ...filterValues, name: value }, {});
    }


    const tableData: Array<Object> = [];
    ids.forEach(id => {
        tableData.push(data[id])
    });
    return (
        <>
            <Search
                className='item-search'
                allowClear
                placeholder="Search by name"
                onSearch={onSearch}
                enterButton
                style={{ width: '25%', float: 'left', margin: '20px' }}
            />
            <GeneralFilter
                filterValues={filterValues}
                setFilterValues={setFilters}
                applicationsResource={"applications"}
                style={{ float: 'right', margin: '20px', width: "350px" }}
                stateFilterEnabled={false}
                changeTypeFilterEnabled={false}
            />
            <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
                <Table pagination={{
                    pageSizeOptions: [25, 50, 100],
                    showSizeChanger: true,
                    onShowSizeChange: (_current, size) => setPerPage(size),
                    total: total,
                    pageSize: perPage,
                    current: page,
                }}
                    rowKey="id"
                    columns={columns}
                    dataSource={tableData}
                    onChange={tableOnChangeGenerator(setSort, page, setPage)}
                />
            </SkeletonTable>
        </>
    )
}

export default ListView;
