import { Card, Table, Typography } from 'antd';
import * as React from 'react';
import {
  ListBase, ListProps, useListContext
} from 'react-admin';
import ReactJson from 'react-json-view';
import {Link, useLocation} from 'react-router-dom';
import { formatDateAndTime } from '../../helpers/utils';
import { ApplicationRecord } from '../../types';
import SkeletonTable, { SkeletonTableColumnsType } from '../Common/SkeletonTable';
import {useEffect, useState} from "react";
import {onExpandIcon} from "../Common/tableUtils";

const expandedRowRender = (record: ApplicationRecord) => {
  return (
    <Card>
      <ReactJson style={{ padding: '20px' }} src={record.metadata} displayArrayKey={false} displayDataTypes={false} name="meta" displayObjectSize={false} />
    </Card>
  )
}
const ListView = () => {
  const { ids, data, loading, total, setPage, perPage, setPerPage, page } = useListContext();
  const [expandedRow, setExpandedRow] = useState(null);
  const location = useLocation();

  const toggleRowExpansion = (key: any) => setExpandedRow(current => (current === key ? null : key));

  useEffect(() => {
    toggleRowExpansion(location.hash.substring(1))
  }, [location]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Number of Service Items',
      dataIndex: 'num_service_items',
      key: 'num_service_items',
      render: (text: any, record: ApplicationRecord) => <Link to={`/service_items?filter={"application_id":"${record.id}"}`} component={Typography.Link}>{text}</Link>,
    },
    {
      title: 'Pending Change Instances',
      dataIndex: 'num_pending_changes',
      key: 'num_pending_changes',
      render: (text: any, record: ApplicationRecord) => <Link to={`/change_instances?filter={"application_id":"${record.id}","state":"PENDING"}`} component={Typography.Link}>{text}</Link>
    },
    {
      title: 'Approved Change Instances',
      dataIndex: 'num_approved_changes',
      key: 'num_approved_changes',
      render: (text: any, record: ApplicationRecord) => <Link to={`/change_instances?filter={"application_id":"${record.id}","state":"APPROVED"}`} component={Typography.Link}>{text}</Link>
    },
    {
      title: 'Completed Change Instances',
      dataIndex: 'num_completed_changes',
      key: 'num_completed_changes',
      render: (text: any, record: ApplicationRecord) => <Link to={`/change_instances?filter={"application_id":"${record.id}","state":"COMPLETED"}`} component={Typography.Link}>{text}</Link>
    },
    {
      title: 'Last Modified',
      key: 'modified',
      render: (text: any, record: any) => formatDateAndTime(record.modified)
    },
  ]

  const tableData: any[] = []
  ids.forEach(id => {
    tableData.push(data[id])
  });
  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Applications"
    >
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          rowKey="name"
          columns={columns}
          pagination={{
            onShowSizeChange: (_current, size) => setPerPage(size),
            showSizeChanger: true,
            pageSizeOptions: [25, 50, 100],
            total: total,
            pageSize: perPage,
            current: page,
            onChange: page => setPage(page)
          }}
          dataSource={tableData}
          expandable={{
            expandedRowRender,
            expandRowByClick: true,
            expandedRowKeys: expandedRow ? [expandedRow] : [],
            expandIcon: onExpandIcon(toggleRowExpansion, undefined),
          }}
          onRow={(record) => { return { onClick: () => { toggleRowExpansion(record.name) } } }}
          locale={{ emptyText: "Applications will display here once a declaration has been sent" }}
        />
      </SkeletonTable>
    </Card>
  )
}

const ApplicationList = (props: ListProps) => {

  return (
    <ListBase perPage={25} {...props} filter={{light: true}}>
      <ListView />
    </ListBase>
  )
}

export default ApplicationList;
