import React, {useState} from "react";
import {useQuery} from "react-admin";
import {Card, Spin, Table, Tabs} from "antd";
import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import ReactDiffViewer from "react-diff-viewer";
import TabPane = Tabs.TabPane;
import LazyExpandedRow from "../Common/LazyExpandedRow";
import ConsumerChangeInstanceExpandedRow from "../ChangeInstances/ChangeInstanceRow";
import {onExpandOnlyOneRow} from "../Common/tableUtils";
import {ChangeInstanceMiniColumns} from "../Common/Columns";

const SubmissionExpandedRow = ({ record }: { record: Record<string, any> }) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

    const spinnerElement = <Spin size="large" tip="Loading"><Card style={{height: "100px"}}></Card></Spin>

    const { data } = useQuery({
        type: 'getList',
        resource: "change_instances",
        payload: {
            pagination: { page: 1, perPage: 20 },
            sort: { field: 'name', order: 'asc' },
            filter: { submission_id: record.id, light: true }
        }
    });

    return (
        <Card>
            {
                data?
                    <Tabs defaultActiveKey="1" type='card'>
                        <TabPane tab="Change Instances" key="1">
                            <SkeletonTable columns={ChangeInstanceMiniColumns as SkeletonTableColumnsType[]}>
                                <Table
                                    rowKey="id"
                                    columns={ChangeInstanceMiniColumns}
                                    dataSource={data}
                                    pagination={false}
                                    expandedRowKeys={expandedRowKeys}
                                    expandable={{
                                        expandedRowRender: (record: any) => <LazyExpandedRow lightRecord={record} resource={"change_instances"} ExpandedRowComponent={ConsumerChangeInstanceExpandedRow}/>,
                                        expandRowByClick: true,
                                        onExpand: onExpandOnlyOneRow(setExpandedRowKeys)
                                    }}
                                />
                            </SkeletonTable>
                        </TabPane>
                        <TabPane tab="Metadata" key="2">
                            <ReactDiffViewer
                                leftTitle={"Old Declaration"}
                                rightTitle={'New Declaration'}
                                oldValue={JSON.stringify(record?.previous_metadata||{}, null, 4)}
                                newValue={JSON.stringify(record?.metadata, null, 4)}
                                splitView={true}
                            />
                        </TabPane>
                    </Tabs>
                    :
                    spinnerElement
            }
        </Card>
    );
}

export default SubmissionExpandedRow;
