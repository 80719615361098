
import { Reducer } from 'redux';
import { SET_SERVICE_ITEMS_VIEW_MODE, SERVICE_ITEMS_GRID_VIEW, SET_VIEW_MODE, SET_WELCOME_SCREEN_SHOWN } from '../actions/uiActions'

const defaultState = {
    viewMode: 'CONSUMER',
    welcomeScreenShown: false,
    serviceItemsViewMode: SERVICE_ITEMS_GRID_VIEW
};

const uiReducer: Reducer = (
    previousState = defaultState,
    action
) => {
    switch (action.type) {
        case SET_VIEW_MODE:
            return {
                ...previousState,
                viewMode: action.payload,
            };
        case SET_SERVICE_ITEMS_VIEW_MODE:
            return {
                ...previousState,
                serviceItemsViewMode: action.payload,
            };
        case SET_WELCOME_SCREEN_SHOWN:
                return {
                    ...previousState,
                    welcomeScreenShown: action.payload,
                };
        default:
            return previousState;
    }
}

export default uiReducer;