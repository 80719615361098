import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Card, Radio } from 'antd';
import React from 'react';
import {ListBase, ListProps, ReduxState,} from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { SERVICE_ITEMS_GRID_VIEW, SERVICE_ITEMS_LIST_VIEW, setServiceItemsViewMode } from '../../actions/uiActions';
import GridView from './GridView';
import ListView from './ListView';


const ServiceItemsList = (props: ListProps) => {
  const serviceItemsViewMode = useSelector((state: ReduxState) => state.ui.serviceItemsViewMode);
  const dispatch = useDispatch();

  const onChangeView = (e: any) => dispatch(setServiceItemsViewMode(e.target.value));

  return (
    <Card
      bordered={false}
      className="criclebox tablespace mb-24"
      title="Service Items"
      extra={
        <Radio.Group onChange={onChangeView} buttonStyle="solid" defaultValue={serviceItemsViewMode}>
          <Radio.Button value={SERVICE_ITEMS_GRID_VIEW}><AppstoreOutlined /></Radio.Button>
          <Radio.Button value={SERVICE_ITEMS_LIST_VIEW}><UnorderedListOutlined /></Radio.Button>
        </Radio.Group>
      }
    >

      <ListBase
        sort={{ field: 'id', order: 'DESC' }}
        filter={{light: true}}
        perPage={25}
        {...props}
      >
        <div>
          {serviceItemsViewMode == SERVICE_ITEMS_GRID_VIEW ? <GridView /> : <ListView />}
        </div>
      </ListBase>
    </Card>
  )
}

export default ServiceItemsList;
