import React, {useEffect, useState} from "react";
import {Card, Col, DatePicker, Menu, Row, Space, Table, Tooltip, Typography} from "antd";
import SkeletonTable, {SkeletonTableColumnsType} from "../Common/SkeletonTable";
import {Link, ListBase, ListProps, useDataProvider, useListContext} from "react-admin";
import {tableOnChangeGenerator} from "../Common/tableUtils";
import GeneralFilter from "../ChangeInstances/GeneralFilter";
import {getCurrency, getPOVResources} from "../Common/utils";
import {CaretRightOutlined, CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import {formatDateAndTime} from "../../helpers/utils";
import TotalChargesWidget from "../Dashboard/widgets/totalCharges";
import {ExtendedDataProvider} from "../../types";
import ChargeChart from "./ChargeChart";
import {PriceTag} from "../Common/Tags";
const { Text } = Typography;
const { RangePicker } = DatePicker;


const ChargeListView = () => {
    const { ids, data, loading, setSort, filterValues, setFilters, resource, perPage, setPage, setPerPage, page, total } = useListContext();
    const dataProvider: ExtendedDataProvider = useDataProvider();
    const [totalCharges, setTotalCharges] = useState<any>(null);
    const currency = getCurrency()

    const pov = resource==="consumer_charges"?"consumer":"serviceowner"
    const generalFilterResources = getPOVResources(pov)

    useEffect(() => {
        dataProvider.getAction(resource, { verb: 'accumulated', filter: filterValues })
            .then((response: any) => { setTotalCharges(response.data) })
            .catch((error: any) => { console.log(error) })
    }, [filterValues])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Service Item',
            dataIndex: 'scope',
            key: 'scope',
            render: (scope: any) => (
                <Link to={`/${pov==="consumer"?'service_items':'owner_service_items'}/${scope.scope==='change_instance'?scope.data.service_item.id:scope.data.id}`}>
                    <Tooltip title="Go to service item page">
                        <Text keyboard>
                            {scope.scope==='change_instance'?scope.data.service_item.name:scope.data.name}<CaretRightOutlined />
                        </Text>
                    </Tooltip>
                </Link>
            )
        },
        {
            title: 'Application',
            key: 'application',
            render: (record: any) => (record.scope.scope==='change_instance'?record.scope.data.application_name:record.scope.data.application.name)
        },
        {
            title: 'Charge Type',
            dataIndex: 'charge_type',
            key: 'charge_type',
        },
        {
            title: 'Total Charge',
            dataIndex: 'total_charge',
            key: 'total_charge',
            render: (record: number) => <PriceTag amount={record} currency={currency}/>
        },
        {
            title: 'Processed',
            dataIndex: 'processed',
            key: 'processed',
            render: (processed: boolean) => (
                processed?
                    <CheckCircleOutlined style={{ color: '#a0d911' }}/>
                    :
                    <CloseCircleOutlined style={{ color: '#f5222d' }}/>
            )
        },
        {
            title: 'Created',
            key: 'start',
            render: (record: any) => formatDateAndTime(record.start),
        },
    ]

    const timeRangeOnChange = (dates: any , dateStrings: any) => {
        var range_start = '';
        var range_end = '';
        if (dates) {
            if (dates[0]) {
                const range_start_ = dates[0];
                range_start_.set ('hour', 0);
                range_start_.set ('minute', 0);
                range_start_.set ('second', 0);
                range_start_.set ('millisecond', 0);
                range_start = range_start_.toISOString ()
            }

            if (dates[1]) {
                const range_end_ = dates[1]
                range_end_.set ('hour', 23);
                range_end_.set ('minute', 59);
                range_end_.set ('second', 59);
                range_end_.set ('millisecond', 0);
                range_end = range_end_.toISOString ()
            }
        }

        setFilters({
            ...filterValues,
            range_after: range_start,
            range_before: range_end
        }, {});
    }

    const tableData: Array<Object> = [];
    ids.forEach(id => { tableData.push(data[id]) });
    return (
        data?
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Charges"
        >
            <Row>
                <Col span={12} style={{padding: '30px'}}>
                    <ChargeChart resource={resource} filterValues={filterValues}/>
                    { totalCharges && <TotalChargesWidget data={totalCharges}/> }
                </Col>
                <Col span={12}>
                    <Space style={{ width: '100%' }} direction="vertical">
                        <GeneralFilter
                            filterValues={filterValues}
                            setFilterValues={setFilters}
                            style={{ float: 'right', margin: '20px', width: "350px", marginBottom: '0px' }}
                            stateFilterEnabled={false}
                            changeTypeFilterEnabled={false}
                            {...generalFilterResources}
                        />
                        <Text style={{ float: 'right', width: "350px", marginRight: '20px' }}>
                            Filter by date:
                        </Text>
                        <RangePicker
                            style={{ float: 'right', width: "350px", marginRight: '20px', marginBottom: '20px' }}
                            onChange={timeRangeOnChange}
                            allowEmpty={[true, true]}
                        />
                    </Space>
                </Col>
            </Row>

            <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
                <Table
                    rowKey='id'
                    columns={columns}
                    pagination={{
                        pageSizeOptions: [25, 50, 100],
                        onShowSizeChange: (_current, size) => setPerPage(size),
                        showSizeChanger: true,
                        total: total,
                        pageSize: perPage,
                        current: page,
                    }}
                    dataSource={tableData}
                    onChange={tableOnChangeGenerator(setSort, page, setPage)}
                />
            </SkeletonTable>
        </Card>
            :
            <>loading</>
    )
}
const ChargeList = (props: ListProps) => {
    return (
        <ListBase sort={{ field: 'id', order: 'DESC' }} perPage={25} {...props}>
            <ChargeListView />
        </ListBase>
    )
}

export default ChargeList;
